// Source: Traveller RPG Wiki (CC-BY-NC 3.0)
// <https://wiki.travellerrpg.com/Canon_World_%26_System_Reference_Log>

export { names as default }

const names: string[] = [
"aazuni",
"abarre",
"acorn",
"acqua iii",
"adabicci",
"adawi",
"adhara",
"aegadh",
"aeghvoekogz",
"aetskaekhoun",
"aga sugek",
"agari",
"agidda",
"ahbrazz",
"ahetaowa",
"ahfatre",
"ahoahea",
"aiyar",
"akabar",
"akeen",
"akhamin",
"aki",
"akitilon",
"alaoyaah",
"aldaya",
"alekvadin",
"alell",
"alenzar",
"algine",
"alike",
"alkhaas",
"alki",
"alleman",
"alphaaric",
"alsatia",
"altiplano",
"amarsi",
"amberinth",
"amdani",
"ameros",
"amnukun",
"amsa",
"amulric",
"anamoni",
"anaxar",
"anaxias",
"anderson",
"andor",
"anenerkuk",
"angebo",
"anghurr",
"angvae",
"ankhir",
"anomaly five",
"anselhome",
"ansenz",
"ansirk",
"answerin",
"antares",
"anther",
"antiquity",
"antra",
"aosta",
"aoukeiwar",
"aramanx",
"aramis",
"arba",
"arcturus",
"arden",
"arisia",
"arleshanu",
"arlyasoi",
"arnorac",
"arrilla",
"arukhur",
"asduad",
"asena",
"asgard",
"ashima",
"ashishinipar",
"ashki",
"ashton",
"asli",
"asmodeus",
"asteltine",
"aston",
"atadl",
"atalanta",
"atsah",
"attax",
"auitawry",
"aulryakh",
"aursis",
"avalar",
"avat",
"azu",
"azun",
"bacelbron",
"backman",
"bael",
"bahadur",
"balaclaron",
"banasdan",
"bantral",
"barnard",
"barony",
"barrington",
"barron",
"bastion",
"beater",
"beck's world",
"belicose",
"belizo",
"bellerophon",
"bellham",
"belumar",
"bendor",
"berange",
"beso",
"beta",
"bifirs",
"biggles",
"bincia",
"birii",
"biter",
"bogustin",
"bokilbom",
"bones",
"bonnhelm",
"borlund",
"boskone",
"boughene",
"bremen",
"brewin",
"bronze",
"brygella",
"buagki",
"byret",
"byrna",
"cadion",
"cafad",
"caladbolg",
"calit",
"cambria",
"campobello",
"candory",
"capital",
"capon",
"carey",
"carl's world",
"carlton",
"carlyle",
"carrillan belt",
"carse",
"carsten",
"castell",
"castor",
"catanin",
"catseye",
"celepina",
"celetron",
"channel",
"chaosheo",
"charon",
"chemi",
"chou",
"chronor",
"chrysolite",
"chwistyoch",
"cicero",
"cikuek",
"cipango",
"cipatwe",
"citenic",
"clan",
"clarissa",
"clown",
"codsen",
"cogri",
"colada",
"collace",
"coppelia",
"coralee",
"corfinium",
"corfu",
"corve",
"cosenza",
"cossor",
"coventry",
"crozat",
"culdee",
"cumber",
"cunnonic",
"cyan",
"cymbeline",
"cyril",
"daama",
"daasha",
"darkmoon",
"darrian",
"dashuu",
"dathsuts",
"datrillian",
"datrumna",
"daumier",
"dawn",
"debarre",
"dekhii",
"dema",
"demick",
"deneb",
"denotam",
"dentus",
"uugnii",
"bloddwyn",
"halena",
"robinson",
"dartus",
"stalker",
"ultraneta",
"kenash",
"de boynton",
"derchon",
"desdemone",
"dethenes",
"deyis ii",
"deymun na",
"dezik",
"d'ganzio",
"dhaengae",
"dhian",
"diana",
"diiron",
"diishalum",
"dingir",
"dinhe",
"dinom",
"dinomn",
"dishadshii",
"disi",
"divad",
"djinni",
"dlaekan",
"dlan",
"dodardudos",
"domitar",
"doom",
"dorp",
"dover",
"drakriedie",
"drovinthic",
"dtorg",
"duale",
"dukh",
"durendal",
"durima",
"duumur",
"dynam",
"dyrnwyn",
"dywosik",
"dzarrvaer",
"dzuerongvoe",
"eakhta",
"ea'tiva",
"eauale",
"ebasha",
"eberly",
"eddum",
"edinina",
"eery",
"efate",
"egypt",
"ekal",
"ekhiysus",
"ektorr",
"ektron",
"ektuurim",
"eleusis",
"elyan",
"ember",
"emerald",
"enaaka",
"endup",
"engorr",
"engrange",
"enki kalamma",
"enmish",
"enope",
"enos",
"enrique",
"entrope",
"equus",
"ergo",
"erita",
"erlu",
"esalin",
"esngougz",
"esui",
"eta-gu",
"excalibur",
"exile",
"extolay",
"faarzgaen",
"fafhrd",
"far portal",
"far trinity",
"farreach",
"fema",
"feme",
"feneteman",
"fenris",
"feri",
"ferot",
"ferry",
"finestre",
"fist",
"flammarion",
"flamme",
"flanders",
"flire",
"floria",
"focaline",
"foelen",
"fomalhaut",
"forboldn",
"forine",
"foriv",
"forlorn",
"fornice",
"fornol",
"forward",
"fosey",
"frenzie",
"frey",
"frisgar",
"f'rnow",
"ftahalr",
"fterlyuhlye",
"fugawa",
"fulacin",
"fuquay",
"furioso",
"gaajpadje",
"gaashushnu lii mur",
"gabrael",
"gadden",
"gaea",
"gaelaeth",
"gagh veth",
"gaknou val",
"galiano",
"galvaren",
"gamgilebo",
"gandar",
"ganyvar",
"garaag",
"garda-vilis",
"garden",
"garrison",
"gasha",
"gashidda",
"gashiila",
"gashikan",
"gaspar belt",
"gateway",
"gavza",
"gazulin",
"gegirur",
"gegua",
"gemid",
"gengfok",
"geniishir",
"geokha",
"gershunas",
"g'haenbaz",
"ghaerthu",
"ghaez-larrgh",
"ghandi",
"ghatsokie",
"ghi-nabrin'l",
"ghi-radul",
"ghurrllekh",
"giidirur",
"giinam",
"giirsha",
"gilboa",
"gilden",
"gileden",
"ginning",
"girshi",
"gishinridu",
"githiaski",
"gkakhaellan",
"glea",
"glisten",
"gokodeyo",
"goldstar",
"gollere",
"gomms",
"gorky",
"gougeste",
"gowandon",
"gracilis",
"gram",
"granporr",
"grant",
"granth",
"gravin",
"guaran",
"gugud",
"gurzueng",
"gustavus",
"guusimka",
"gvaellekh",
"gvurrdon",
"gzolakhgha",
"gzorraeth",
"haden",
"hades",
"halka",
"halo",
"halthome",
"hamilcar",
"hammet",
"harappa",
"hasaggan",
"hasawe'i",
"hasdrubal",
"havaiikii",
"havary",
"hefry",
"heguz",
"helene",
"hellhole",
"henoz",
"hephaistos",
"herakles",
"heraklion",
"heraldia",
"hessel",
"hewes",
"hewlett",
"hexos",
"heya",
"hice",
"higashi",
"hiilev",
"hike",
"hillalladi",
"hindahl",
"hitoku",
"hkeakewoirea",
"hkurlei",
"hlao",
"hliyh",
"hluahyahe",
"hoahtei",
"hoatzin",
"hoilhapuli",
"hollis",
"home",
"hrealeiwea'",
"hrike",
"hroilr",
"hrun",
"hteoe",
"huderu",
"hudmill",
"hunberri",
"huran",
"iaasiv",
"iacus",
"ifuwana",
"iggarir",
"ighoth",
"igudi",
"igunfaksa",
"iilike",
"ikhaeal",
"ikhog",
"ikhur",
"iktaitlow",
"ikuk",
"ilelish",
"ilium",
"ilma",
"imaparlu",
"imisaa",
"inar",
"inidu",
"intelia",
"inthe",
"inthra",
"iouo",
"ipadmali",
"iraisafeie",
"irashdaa",
"iren",
"irgiida",
"irila",
"irlaggur",
"irlu",
"iron",
"isee",
"ishadar",
"ishimshulgi",
"ishirdu",
"isurkun",
"itasis",
"ivora",
"ixteline",
"iyakhoryeh",
"izir",
"jacent",
"jae tellona",
"jaeyelya",
"jafla",
"janosz",
"jdiprqrafr",
"jebiina",
"jenghe",
"jesedipere",
"jewell",
"jode",
"jone",
"jonen",
"jonkeer",
"joyeuse",
"jubal",
"juess",
"junction",
"junidy",
"kaasu",
"kafal",
"kaflowe",
"kagamira",
"kaggushus",
"kaguk",
"kaiid",
"kakhiw",
"kamurinmur",
"kandom",
"kanorb",
"kapabu sepple",
"karamursel",
"kargar",
"karin",
"karkhar",
"karroo",
"kasaan",
"kashaashii",
"kashin",
"kasmar",
"katarulu",
"katsuto",
"kdept",
"keanou",
"kedhnang",
"keng",
"kengighon",
"keplo",
"keshi",
"ketola",
"kfe-gou",
"kfolaell",
"khalikkam",
"khankari",
"khavle",
"khedish",
"khikaba",
"khimudire",
"khipge",
"khirar",
"khishan",
"khoez-toeng-thos",
"khugan",
"khughfudz",
"khukish",
"khula",
"khulampu",
"khyaleiftaoher",
"kidagir",
"kidashi",
"kigaru",
"kiirindor",
"kikasad",
"kinorb",
"kiran",
"kirg",
"kirur",
"kiur",
"kkirka",
"kloackin",
"knabbib",
"knoerrgh",
"knorbes",
"koergfoes",
"kolan",
"konya",
"korbak",
"kovuu",
"kri",
"krypton",
"ksuerrgh",
"kubishush",
"kuk",
"kukulcan",
"kumorle",
"kurae",
"kusyu",
"laberv",
"lablon",
"lagna",
"lair",
"lalandra",
"lancelot",
"lanth",
"lanu",
"laputa",
"larkarda",
"larmige",
"larspri",
"lasher",
"lashkagur",
"lashlaminan",
"lasiter",
"lavinia",
"lazlo's world",
"lectorsen",
"ledge",
"leenitakot",
"lefiguura",
"lemish",
"leroy",
"lewis",
"liasdi",
"libert",
"lilad",
"lintl",
"liran",
"lishun",
"liwar",
"llaegharrgh",
"lling",
"lloursouth",
"lluezukgzang",
"lode",
"l'oeul d'dieu",
"lohreifai'o",
"loki",
"lome",
"loskene",
"louzy",
"ludmilla",
"lugaluru",
"lukham",
"lunion",
"luunbu",
"lysen",
"macene",
"madden",
"magash",
"maggiv",
"maitz",
"manshuruk",
"maran",
"marastan",
"marsus",
"mashnamii",
"masionia",
"mater",
"medurma",
"melchior",
"meleto",
"menorb",
"mercury",
"mertactor",
"meshan",
"midway",
"minde's star",
"mirabilis",
"mire",
"mirriam",
"mirror",
"miskatonic",
"mithras",
"mithril",
"moncton",
"mongo",
"monterey",
"montezuma",
"mora",
"moran",
"morgana",
"morimur",
"morninglori",
"muan gwi",
"mudge",
"muimarir",
"mukhaldim",
"murcia",
"muugagen",
"naadi",
"naagasa",
"nabokov",
"nakege",
"namidshur",
"nape",
"narquel",
"narsil",
"narval",
"narya",
"nasemin",
"naston",
"nasu",
"natoko",
"neghu oug",
"neola",
"neopis",
"neto",
"netti",
"new sydney",
"newcastle",
"newcomb",
"nexine",
"ngirae",
"ngoerrgh",
"ngorsraek",
"night",
"nihesi",
"nike",
"ningho",
"ninjar",
"ninkhur sagga",
"nirton",
"nisinasha",
"niven",
"nonym",
"noricum",
"noricum",
"norijo",
"nosea",
"nulinad",
"nulisud",
"nusku",
"nyarlathotep",
"ochre",
"odinaga",
"oertsous",
"oifuerr",
"oihoiei",
"okefenokee",
"okefir",
"okiji",
"okskful",
"olden",
"olny",
"olxber",
"olympia",
"onon",
"oorpic",
"opar",
"oppenheim",
"oppenheimer",
"orcana",
"oskkan",
"otsaellghue",
"oudh",
"ouifauhai",
"oukgzueredh",
"ouskathoerz",
"outpost",
"oyoiy",
"ozuvon",
"pacific",
"pagliacci",
"palama",
"pallique",
"pamraeltan",
"pandrin",
"pannet",
"parade",
"paro",
"parshi",
"pashus",
"patinir",
"patnor",
"pautho",
"pavabid",
"pavanne",
"pax rulin",
"paya",
"penelope",
"peraspera",
"peres",
"perrior",
"p'hakar",
"phaln",
"pharsalus",
"phireene",
"phlume",
"picard's belt",
"pierson",
"pilgham",
"pimane",
"pirema",
"pixie",
"planck",
"plauka",
"plavis",
"plunge",
"pollux",
"ponand",
"popok",
"porozlo",
"poseidon",
"pradman",
"preserve",
"pretoria",
"prevarren",
"prometheus",
"promise",
"protalus",
"prt'aow",
"pscias",
"ptolemy",
"purdishi",
"pysadi",
"qevar",
"quafe",
"quar",
"quelleff",
"querion",
"quiru",
"quopist",
"raasaraa",
"raiga",
"rapp's world",
"raschev",
"raweh",
"rayel",
"r'bak",
"reacher",
"rebin",
"rech",
"redemption",
"redi",
"reel",
"reference",
"regina",
"regis",
"remington",
"remulak",
"repository",
"rethe",
"retinae",
"rhydino",
"rhylanor",
"riarette",
"riies",
"riinel",
"rilke",
"rill",
"rington",
"risek",
"roaa",
"robin",
"roeknughroe",
"roetingaz",
"roget",
"romar",
"ronni",
"rorre",
"rouen",
"roup",
"rronaetuts",
"ruby",
"ruffian",
"rugbird",
"ruie",
"rushu",
"saandeth",
"sabmiqys",
"sabsee",
"sacnoth",
"saell",
"saezghen",
"saki",
"sakin",
"salaam",
"salur",
"samarorn",
"sandhaven",
"sardia",
"sardis",
"sarpedon",
"sashingun",
"saurus",
"scandia",
"scaramouche",
"scipio",
"sebasta",
"seleen",
"sestao",
"shaalgar",
"shabii",
"shagipka",
"shakhamash",
"sharasham",
"shareduu",
"sharim",
"sharkhagu",
"shazam",
"sherad",
"sheshene",
"shibashliim",
"shigi",
"shinku",
"shinorasus",
"shionthy",
"shirene",
"shishkala",
"shiwonee",
"shoehorn",
"shuama",
"shudash",
"shudusham",
"shulgiasu",
"shulimik",
"shululsish",
"shuna",
"shunim",
"shupin",
"shuruppak",
"sikilar",
"silest",
"silverring",
"sinta",
"sionnach",
"sirius",
"sittahr",
"six gun",
"skanderbeg",
"sketola",
"skkyhrk",
"skull",
"skyhaven",
"slurn",
"smade's planet",
"smoug",
"smuggler's hide",
"sofar",
"sonthert",
"soyuz",
"spectrum",
"spume",
"st george",
"starn",
"stasin",
"staten",
"steel",
"steisua",
"stenardee",
"sterling",
"stern-stern",
"stijn",
"sting",
"storm",
"stralsund",
"strat",
"strela",
"strouden",
"sufren",
"sulawesi",
"sumeszu",
"sunz",
"surli",
"susadi",
"suthgzu",
"syoakh",
"syreon",
"sysix",
"szirp",
"tahaver",
"tainbee",
"takaria",
"taknarkag",
"taksarrgh",
"talca",
"tamarind",
"taraeng",
"taravesh",
"tarin sink",
"tarkaan",
"tarkine",
"tarsus",
"tarsus",
"tash",
"tauri",
"tavarand",
"tavonni",
"tazmania",
"teh",
"tempri",
"temra",
"tenalphi",
"tensas",
"tephany",
"terant",
"terra",
"terra far",
"tetrini",
"teucer",
"tewfik",
"thaedh",
"thaggesh",
"thagh",
"thanber",
"tharver",
"thetis",
"theton",
"thicket",
"thisbe",
"thoegzknaedz",
"thorell",
"thorwald",
"thun",
"tiawan",
"timat",
"tinea-fabre",
"tinskeep",
"tionale",
"tireen",
"tirem",
"tizon",
"tlaistole'",
"tobia",
"tollori",
"tonnes",
"tonnurad",
"tonopah",
"torm",
"torment",
"torrel",
"torrrrerz",
"tovis",
"towers",
"trapezus",
"treece",
"tremous dex",
"trevannic",
"trevor",
"trexalon",
"trifuge",
"trill",
"trin",
"trindel",
"tripolis",
"tristar",
"trokusian",
"tsinan",
"tuktaar",
"tulane",
"tureded",
"tur-hunar",
"turin",
"twophur",
"uakye",
"ucella",
"udipeni",
"ueridh",
"uerzthu",
"ugdukida",
"ul",
"umaraag",
"umber",
"umgadin",
"umilikha",
"umorphutwyo",
"uneshuu",
"ungarn",
"ungik",
"untida",
"uris belt",
"urnas",
"usdiki",
"ushuuri",
"uson",
"uston",
"uthith",
"utoland",
"uughrae",
"uzotssaza",
"vaal",
"vahana",
"vakada",
"vala",
"valkyr",
"vanejen",
"vantage",
"vapour",
"varan's belt",
"vard",
"vashtii",
"vaward",
"veel",
"velpare",
"venad",
"ventura",
"veppim",
"vhodan",
"viceroy",
"victoria",
"viiman",
"vilis",
"vincennes",
"violante",
"virshash",
"vision",
"viyard",
"vland",
"vole",
"vornam",
"vosrin",
"vreibefger",
"wagnild",
"wahtoikoeakh",
"wair",
"wake",
"waken",
"walston",
"wal-ta-ka",
"wapiti",
"wardn",
"warinir",
"waverly",
"weipu",
"westfir",
"westminster",
"whanga",
"wikk",
"wimorel",
"windfleet",
"windsor",
"winston",
"wiyeare",
"wochiers",
"woi'akiyo",
"woomera",
"wyn",
"wypoc",
"xantippe",
"xapoqoz",
"xenon",
"xiwa",
"yaiy'aho",
"yard",
"yebab",
"yehta",
"ye-lu",
"yeyofui'",
"ylaven",
"yokonabe",
"yoma",
"yorbund",
"yori",
"york",
"youghal",
"yres",
"ys",
"yubitti",
"zaibon",
"zakishe",
"zamine",
"zamoran",
"zannokh",
"zdovesil",
"zeda",
"zeen",
"zemud",
"zhanora",
"zhdant",
"zila",
"zimmel",
"zircon",
"zivije",
"zora",
"zuiar",
"zukhimie",
"zupilak",
"zurr",
"zurrian",
"zykoca"
];
